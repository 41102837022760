import {React, useState, useEffect} from 'react';
import Divider from './individual/Divider';
import Spacer from './individual/Spacer';
import Table from './individual/Table';
import TableToggle from './individual/TableToggle';
import Title from './individual/Title';
import { useHistory} from "react-router-dom";

export default function Catalogus() {
    const [products, setProducts] = useState([])
    const [productSuppl, setProductsSuppl] = useState([])
    let history = useHistory();
    const [effect, setEffect] = useState(0);


    useEffect(() => {
        const getProducts = async() => {
            const res = await fetch('https://api.2023.leonidas.optimize-it.be/catalogue/list/products/', 
            { 
              headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
            }
          )
            const data = await res.json()
            setProducts(data)
        }
        const getProductsSuppl = async() => {
          const res = await fetch('https://api.2023.leonidas.optimize-it.be/catalogue/list/supplements/', 
          { 
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
          }
        )
          const data = await res.json()
          setProductsSuppl(data)
        }
        getProducts();
        getProductsSuppl();
    }, [effect])

    const toggleOff = async(id) => {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') }
      };
      const res = await fetch('https://api.2023.leonidas.optimize-it.be/catalogue/list/hide/' + id + '/', requestOptions);
      const data = await res.json();
      console.log(data)
      if (data == "OK") {
        setEffect(effect + 1)
      } else {
        alert("An unexpected server error occured.")
      }
    }

    const toggleOn = async(id) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') }
      };
      const res = await fetch('https://api.2023.leonidas.optimize-it.be/catalogue/list/show/' + id + '/', requestOptions);
      const data = await res.json();
      console.log(data)
      if (data == "OK") {
        setEffect(effect + 1)
      } else {
        alert("An unexpected server error occured.")
      }
    }

  return ( 
    <div className="mx-24">
      <Spacer height={50} />
      <Title text="Catalogus (assortiment)" type="head" />
      <Spacer height={15} />
      <Divider />
      <Spacer height={50} />
      <TableToggle data={products} fields={['naam', 'etiket']} toggleOff={toggleOff} toggleOn={toggleOn} enabled={true} />
      <Spacer height={50} />
      <Title text="Catalogus (produits supplémentaire)" type="head" />
      <Spacer height={15} />
      <Divider />
      <Spacer height={50} />
      <Table data={productSuppl} fields={['naam']} edit={false} />
      <Spacer height={50} />
    </div>
)};