import { React, useState, useEffect } from 'react';
import { Fragment } from 'react'
import {
  PlusIcon,
  CalendarIcon,
  ChevronDownIcon,
  PencilIcon,
} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'
import {useParams} from "react-router-dom";
import Spacer from './individual/Spacer';
import Table from './individual/Table';
import Title from './individual/Title';
import Divider from './individual/Divider';
import { Link, useHistory } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function ViewClient() {
  const id = useParams()
  const [client, setClient] = useState([])
  const [boxes, setBoxes] = useState([])
  let history = useHistory();
  const [effect, setEffect] = useState(0);

  useEffect(() => {
      const getClient = async() => {
             const res = await fetch('https://api.2023.leonidas.optimize-it.be/client/' + id.id + '/info/', 
             { 
               headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
             }
           )
            const data = await res.json()
             setClient(data)
      }
      const getBoxes = async() => {
            const res = await fetch('https://api.2023.leonidas.optimize-it.be/client/' + id.id + '/boxes/', 
            { 
              headers: {'Authorization': 'Bearer ' + localStorage.getItem('accesstoken')}
            }
          )
            const data = await res.json()
            setBoxes(data)
        }
      getClient();
      getBoxes();
  }, [effect])

    const exportBonDeCommande = () => {
        const link = document.createElement('a');
        const url = 'https://api.2023.leonidas.optimize-it.be/export/xlsx/' + id.id + '/client/'
        link.download = true;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const exportOverzicht = () => {
        const link = document.createElement('a');
        const url = 'https://api.2023.leonidas.optimize-it.be/export/docx/' + id.id + '/client/'
        link.download = true;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const addCarton = async() => {
        if ("ok" == "ok") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('accesstoken') },
            };
            const res = await fetch('https://api.2023.leonidas.optimize-it.be/carton/add/' + id.id + '/client/', requestOptions);
            const data = await res.json();
            setEffect(effect + 1)
        } else {
            alert("Er is een fout opgetreden...")
        }
    }

  return ( 
    <div className="mx-24">
            <Spacer height={50} />
            <div className="lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{client[1]}</h2>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {client[2]}
                </div>
                </div>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">
            <span className="sm:ml-3">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
                        onClick={exportOverzicht}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 -ml-1 mr-2 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        OVERZICHT KARTONS
                    </button>
                </span>
            <span className="sm:ml-3">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-800"
                        onClick={exportBonDeCommande}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 -ml-1 mr-2 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        BON DE COMMANDE
                    </button>
                </span>

                <span className="sm:ml-3">
                <Link to={"/editClient/" + id.id}>
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                        Kartonnummer Wijzigen
                    </button>
                </Link>
                </span>

                <span className="sm:ml-3">
                    <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={addCarton}
                    >
                        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                        Karton toevoegen
                    </button>
                </span>
            </div>
            </div>
            <Spacer height={50} />
            <Title text="Overzicht kartons" type="sub" />
            <Spacer height={15} />
            <Divider />
            <Spacer height={25} />
            <Table data={boxes} fields={['naam', 'inhoud']} edit={true} link="/carton/view/client/" parentID={id.id} type="replace" />
    </div>
)};